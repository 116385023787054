import(/* webpackMode: "eager", webpackExports: ["DayjsLocaleProvider"] */ "/app/common/dayjs.ts");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/app/components/providers/ApolloWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/components/providers/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/providers/ThemeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-medium-image-zoom/dist/styles.css");
;
import(/* webpackMode: "eager" */ "/app/styles/default/main.scss");
;
import(/* webpackMode: "eager", webpackExports: ["StyledComponentsRegistry"] */ "/app/styles/StyledComponentsRegistry.tsx");
